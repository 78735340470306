<template>
  <div>
    <ListAssignDriver></ListAssignDriver>
  </div>
</template>

<script>
import ListAssignDriver from '@core/layouts/components/admin-components/ListAssignDriver.vue'
export default {
  name: 'AssignDriver',
  components: {
    ListAssignDriver,
  },
}
</script>

<style scoped>
</style>
